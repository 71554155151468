export const PROVIDER_MAP: { [key: string]: string } = {
    'Shopify': 'shopify',
    'PayPal': 'paypal',
    'Klarna': 'klarna',
    'AfterPay': 'afterpay',
};

export const MATCH_STATUS_MAP: { [key: string]: string } = {
    'Matched': 'matched',
    'Not Found': 'not_found',
    'No Match': 'no_match',
    'Amount Mismatch': 'amt_mismatch',
    'Period Mismatch': 'date_mismatch',  // TODO: ATW-4 update this value when we change the data in the backend (s3 files)
};
